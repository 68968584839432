import classNames from 'classnames';
import React, { useMemo, useState } from 'react';

import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import CleanPromptSuggestions from 'es-src/screens/HomeScreen/components/CleanPromptSuggestions';
import Highlights from 'es-src/screens/HomeScreen/components/Highlights';
import SearchWithAutocomplete from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete';
import SuggestedQuestions from 'es-src/screens/HomeScreen/components/SuggestedQuestions';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearch/style.module.scss';

const LINE_HEIGHT = 19;

export default function EnterpriseSearch() {
  const {
    loading,
    isResultCardOpen,
    hasError,
  } = useEnterpriseSearchContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [numLines, setNumLines] = useState(1);

  const shouldRenderSuggestedQuestions = !isResultCardOpen && !loading && hasError === null;

  const hasSearchResult = isResultCardOpen && !loading;
  const handleHeightChange = (height: number) => {
    setNumLines(Math.ceil(height / LINE_HEIGHT));
  };
  const dynamicOffsetSpacing = useMemo(() => `${LINE_HEIGHT + numLines * LINE_HEIGHT}px`, [numLines]);

  return (
    <CSSVariableDefiner variables={{ '--offset-spacing': dynamicOffsetSpacing }}>
      <div className={classNames(style.enterpriseSearch, hasSearchResult && style.margin)}>
        <div>
          <div className={isResultCardOpen ? style.fixed : undefined}>
            <SearchWithAutocomplete
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              onHeightChange={handleHeightChange}
            />
          </div>
          <div className={style.cleanPromptWrapper}>
            <CleanPromptSuggestions />
          </div>
        </div>
        { shouldRenderSuggestedQuestions && <SuggestedQuestions /> }
        <Highlights />
      </div>
    </CSSVariableDefiner>
  );
}
