import { FC } from 'react';

import { ScreenKey } from 'global/ScreensConfiguration';
import { MasterFilters } from 'screens/platform/cross-platform-components/context/MasterFiltersContext/MasterFilters';
import FilterMenu from 'screens/platform/cross-platform-components/MasterFiltersPanel/FilterMenu';

import { ReactComponent as directionalityIcon } from 'assets/img/icon/arrows.svg';
import { ReactComponent as clockIcon } from 'assets/img/icon/clock.svg';
import { ReactComponent as appsIcon } from 'assets/img/icon/masterFilters/app-square.svg';
import { ReactComponent as peopleIcon } from 'assets/img/icon/people.svg';
import { ReactComponent as organizationsIcon } from 'assets/img/icon/screens/organizations-screen.svg';
import { ReactComponent as tagsIcon } from 'assets/img/icon/tags.svg';

const FiltersConfig: Record<FilterMenu, { title: string; Icon: FC }> = {
  [FilterMenu.DATES_RANGE]: { title: 'Time', Icon: clockIcon },
  [FilterMenu.TAGS]: { title: 'Tags', Icon: tagsIcon },
  [FilterMenu.PEOPLE]: { title: 'People', Icon: peopleIcon },
  [FilterMenu.APPS]: { title: 'Apps', Icon: appsIcon },
  [FilterMenu.ORGANIZATIONS]: { title: 'Organizations', Icon: organizationsIcon },
  [FilterMenu.DIRECTIONALITY]: { title: 'Directionality', Icon: directionalityIcon },
};

export const ManageFilterScreenKeys: Record<
  Exclude<FilterMenu, FilterMenu.DATES_RANGE | FilterMenu.DIRECTIONALITY>,
  ScreenKey
> = Object.freeze({
  [FilterMenu.APPS]: 'settings.account.apps',
  [FilterMenu.PEOPLE]: 'settings.account.people',
  [FilterMenu.TAGS]: 'settings.account.topics',
  [FilterMenu.ORGANIZATIONS]: 'settings.account.organizations',
});

export const FilterToMasterFilterKey:
  Readonly<Record<FilterMenu, keyof MasterFilters>> = Object.freeze({
    [FilterMenu.DATES_RANGE]: 'datesRange',
    [FilterMenu.TAGS]: 'categories',
    [FilterMenu.PEOPLE]: 'people',
    [FilterMenu.ORGANIZATIONS]: 'organizations',
    [FilterMenu.APPS]: 'apps',
    [FilterMenu.DIRECTIONALITY]: 'directionality',
  });

export default FiltersConfig;
