import classNames from 'classnames';
import { isFuture } from 'date-fns';
import React, { useMemo } from 'react';
import { useAsync } from 'react-use';

import AppIcon from 'common-ui-components/AppIcon';
import ExternalLink from 'common-ui-components/ExternalLink';
import InteractionSubtitle from 'common-ui-components/InteractionTitle/InteractionSubtitle';
import emojiParser from 'common-ui-components/InteractionTitle/parsers/emojiParser';
import SkeletonElement from 'es-src/components/ui/Skeleton/SkeletonElement';
import Size from 'global/lists/Size';
import { Atom } from 'model/Atom';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import InteractionDetailsFooter
  from 'screens/platform/cross-platform-components/InteractionDetails/InteractionDetailsFooter';
import { formatDateRelativelyToNow } from 'utils/DateUtils';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';

import style from 'screens/platform/cross-platform-components/InteractionDetails/style.module.scss';

const NON_BREAKING_SPACE = '\u00A0';

interface Props {
  atom: Atom;
  onClick?: () => void;
}

export default function InteractionDetails({ atom, onClick }: Props) {
  const { persons } = useMetadataContext();

  const {
    appId,
    isPublic,
    mimeType,
    mentionedOrganizations: organizations,
    end,
    permalink,
    tags,
    participants,
    initiatorPersonId,
  } = atom;

  const { value: person, loading } = useAsync(
    () => persons.getById(initiatorPersonId ?? ''),
    [initiatorPersonId],
  );

  const parsedTitleWithEmojis = useMemo(() => emojiParser(atom.title), [atom]);
  const updatedDate = `${isFuture(new Date(end)) ? 'Happening' : 'Updated'} ${formatDateRelativelyToNow(end)}`;

  if (loading) return <InteractionDetailsSkeleton />;

  const createdBy = `Created by ${person?.name}`;
  return (
    <div className={style.interactionDetails}>
      <div className={style.appLogo}>
        <AppIcon
          appId={appId}
          isPublic={isPublic}
          hasBorder={false}
          size={Size.MEDIUM}
          mimeType={mimeType}
        />
      </div>
      <div className={style.interactionContent}>
        <div className={style.title}>
          <span className={style.text}>
            {parsedTitleWithEmojis}
          </span>
        </div>
        <div className={style.separator} />
        <InteractionSubtitle item={atom} showUpdatedAt={false} />
        <span className={style.detail}>{end ? updatedDate : NON_BREAKING_SPACE}</span>
        <span className={style.detail}>{person ? createdBy : NON_BREAKING_SPACE}</span>
        <InteractionDetailsFooter
          tags={Object.keys(tags)}
          organizations={organizations}
          participantsIds={Object.keys(participants)}
        />
      </div>
      {onClick ? (
        <div>
          <ExternalLink
            to={permalink}
            className={style.externalLink}
            onClick={onClick}
          >
            <ExternalLinkIcon className={style.externalLinkIcon} />
          </ExternalLink>
        </div>
      ) : null}
    </div>
  );
}

export function InteractionDetailsSkeleton() {
  return (
    <div className={classNames(style.interactionDetails, style.skeleton)}>
      <SkeletonElement type="avatar" />
      <div className={style.interactionContent}>
        <SkeletonElement type="title" className={style.title} />
        <SkeletonElement type="subtitle" />
        <div className={style.footer}>
          <SkeletonElement type="title" />
        </div>
      </div>
    </div>
  );
}
